// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--43f62";
export var bannerHeading = "Banner-module--bannerHeading--09f7a";
export var bnrImg = "Banner-module--bnrImg--71607";
export var btnsOuter = "Banner-module--btnsOuter--9cf90";
export var computerVisionBanner = "Banner-module--computerVisionBanner--1e2f7";
export var curve = "Banner-module--curve--4d9d6";
export var customeHead = "Banner-module--customeHead--96c4b";
export var description = "Banner-module--description--5ee83";
export var heading = "Banner-module--heading--711d2";
export var list = "Banner-module--list--a1170";
export var listItem = "Banner-module--listItem--6debe";
export var mainTitle = "Banner-module--mainTitle--d2eea";
export var spanColor = "Banner-module--spanColor--a2f3c";
export var transparentBtn = "Banner-module--transparentBtn--db544";